@import "./home.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  padding-left: 10px;
  padding-right: 10px;
}

.wallet-wrapper {
  white-space: nowrap;
  flex-shrink: 0;
}
