.home-animation {
  transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(1deg);
  box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  border-radius: 0.5em;
}

.home-animation:hover {
  transform: rotate3d(0, 0, 0, 0deg) rotate(0deg);
}
